// import auth from "@/middlewares/auth";

import { validRegisterToken } from "@/middlewares/auth";

export const authRoutes = [
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/auth/Login"),
    },
    {
        path: "/logout",
        name: "logout",
        component: () => import("@/views/auth/Login"),
    },
    {
        path: "/register/:token",
        name: "register",
        beforeEnter: [validRegisterToken],
        component: () => import("@/views/auth/Register"),
    },
    {
        path: "/reset-password/:token",
        name: "resetPassword",
        component: () => import("@/views/auth/ResetPassword"),
    },
    {
        path: "/forgot-password",
        name: "forgotPassword",
        component: () => import("@/views/auth/ForgotPassword"),
    },
    {
        path: "/ask-invitation",
        name: "askInvitation",
        component: () => import("@/views/auth/AskInvitation"),
    },

]