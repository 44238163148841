import dayjs from "dayjs";
import { ErrorStore } from "./store/errors";
import { SessionStateStore } from "./store/session_states";

export const helpersPlugin = {
    install: (app) => {
        app.config.globalProperties.isJSON = isJSON;
        app.config.globalProperties.asset = asset;
        app.config.globalProperties.apisset = apisset;
        app.config.globalProperties.isset = isset;
        app.config.globalProperties.$date = $date;
        app.config.globalProperties.errors = errors;
        app.config.globalProperties.dd = dd;
        app.config.globalProperties.only = only;
        app.config.globalProperties.ofilter = ofilter;
        app.config.globalProperties.toggleContract = toggleContract;
        app.config.globalProperties.session = session;
    }
}

/**
 * isJSON
 * 
 * retourne `true` si la string (`str`) en paramètre est un **JSON**
 * 
 * @param {string} str
 * @returns {boolean}
 */
export function isJSON(str){
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

/**
 * asset
 * 
 * retourne le **require()** du `path` mit en paramètre dans le référentiel **"@/assets/"** du projet
 * 
 * @param {String} path
 * @returns {String}
 */
export function asset(path){
    if(path[0] == "/") {
        path = path.substring(1, path.length);
    }
    return require(`@/assets/${path}`);
}

/**
 * apisset
 * 
 * comme asset, mais prend comme base uri `process.env.VUE_APP_API_URL`
 * 
 * Et si c'est une URl complète, elle n'est pas modifiée
 * 
 * @param {string} path 
 * @returns {string}
 */
export function apisset(path){
    if(path[0] == "/") {
        path = path.substring(1, path.length);
    }
    if(path.substring(0, 4) == "http"){
        return path;
    }
    return `${process.env.VUE_APP_API_URL}/${path}`;
}

/**
 * isset
 * 
 * Retourne `true` si la variable (`vars`) en paramètre est différente de : `undefined`, `null`, `NaN`
 * 
 * Si le paramètre `deeper`est à `true`, la fonction checkera également s'il s'agit d'une `Array` ou d'un `Object` **vide**
 * 
 * @param {*} vars 
 * @param {boolean} deeper (facultatif, par défaut : `false`) 
 * @returns {boolean}
 */
export function isset(vars, deeper){
    deeper = deeper || false;

    if(typeof vars == 'string'){
        return vars !== "";
    }
    if(Array.isArray(vars) && deeper){
        return vars.length > 0;
    }
    if(typeof vars === 'object' && deeper){
        return vars !== {};
    }
    return vars !== undefined && vars !== null && !isNaN(vars);
}

/**
 * $date
 * 
 * 
 * @param {String} format
 *  Get the formatted date according to the string of tokens passed in.
 *  
 *  To escape characters, wrap them in square brackets (e.g. [MM]).
 *  ```js
 *  $date('[YYYYescape] YYYY-MM-DDTHH:mm:ssZ[Z]', '2022-08-16') // 'YYYYescape 2022-08-16T00:00:00-02:00Z'
 *  $date('DD/MM/YYYY', '2022-08-16') // '16/08/2022'
 *  ```
 *  Docs: https://day.js.org/docs/en/display/format
 * 
 * @param {Date|Number|String|null} date
 *      (optional) The Date you want to formatted
 * 
 */
export function $date(format, date){
    date = date || new Date();
    return dayjs(date).format(format);
}

/**
 * prod
 * 
 * détermine si l'environnement est de production
 * 
 * @returns {boolean}
 */
export function prod(){
    return process.env.VUE_APP_ENV == "production" || process.env.VUE_APP_ENV == "prod" || process.env.VUE_APP_ENV == "prd"
}

/**
 * usertest
 * 
 * retourne l'utilisateur de test si en "production"
 * 
 * @returns {object} 
 * ```js
 * {
 *      email: process.env.VUE_TEST_DEFAULT_USER,
 *      password: process.env.VUE_TEST_DEFAULT_PWD
 * }
 * 
 * ```
 * 
 */
export function usertest(){
    return {
        email: prod() ? "" : process.env.VUE_TEST_DEFAULT_USER,
        password: prod() ? "" : process.env.VUE_TEST_DEFAULT_PWD
    }
}

/**
 * errors
 * 
 * retourne le error store
 * 
 * @returns {StoreDefinition}
 */
export function errors(){
    return ErrorStore();
}

/**
 * dd
 * 
 * Debug mais seulement si l'appli a le debug mode activé
 * se comporte comme un `console.log()`
 * 
 * @param  {...any} message 
 * @return {void}
 */
export function dd(...message){
    if(process.env.VUE_APP_DEBUG == 'true' || process.env.VUE_APP_DEBUG == true) {
        console.log(...message)
    }
}

/**
 * only
 *      
 *      Filtre les clés d'un objet mis en paramètre
 * 
 * @param {Object} obj 
 *      `Object` à filtrer
 * 
 * @param {Array<String>|String} keys
 *      `Array` de `keys` à filtrer 
 * 
 * @returns {Object}
 */
export function only(obj, keys) {
    keys = Array.isArray(keys) ? keys : [keys];
    return Object.fromEntries(Object.entries(obj).filter(([key]) => keys.includes(key)));
}


/**
 * ofilter
 * 
 *      Filtre un objet comme une array
 * 
 * @param {Object} obj 
 *      `Object` à filtrer
 * 
 * @param {Function} callback
 *      Callback de filtre, le paramètre fournit est la clé de l'objet
 *  
 * @returns {Object}
 */
export function ofilter(obj, callback){
    return Object.fromEntries(Object.entries(obj).filter(callback));
}

/**
 * toggleContract
 * 
 *      Change la catégorie de contrat persistant
 * 
 * @param {String} category 
 */
export function toggleContract(category){
    const session = SessionStateStore();
    session.toContrat(category || '*');
}

/**
 * session
 * 
 *      Récupère l'element SessionStateStore instancié
 * 
 * @returns {SessionStateStore}
 */
export function session(){
    return SessionStateStore();
}


/**
 * filterBy
 * 
 *      Filtre un tableau
 * ```js
 * 
 * let filtered = filterBy(documents, ['category.name', 'name'], 'due');
 * 
 * ```
 * 
 *
 * 
 * @param {Array} arr 
 *  ```js
 *  let array = [{1#document, 2#document..., x#document}];
 *          
 *  let document = [
 *    {
 *      "_keyindex": 0,
 *      "id": 2,
 *      "created_at": "2022-08-10T09:52:39.000000Z",
 *      "updated_at": "2022-08-10T09:52:39.000000Z",
 *      "doctype": "application/pdf",
 *      "path": "private/NGRlYmU5YTMtYzZkOS00ZmNjLTg3YWUtNzQxY2IyMjdkMDYy/8bf1DVKcSemtqMYNN3j0Ub0ELx9JyFY3L634GU0U.pdf",
 *      "name": "FEDER 2021 Inès_signed.pdf",
 *      "contrat_id": null,
 *      "demande_id": null,
 *      "besoin_id": null,
 *      "category_id": 53,
 *      "societe_id": 1,
 *      "user_id": null,
 *      "category_group_id": null,
 *      "contrat_type": "*",
 *      "profil_id": null,
 *      "category": {
 *          "id": 53,
 *          "created_at": "2022-08-10T09:52:39.000000Z",
 *          "updated_at": "2022-08-10T09:52:39.000000Z",
 *          "name": "Pièces jointes",
 *          "desc": "Pièces jointes",
 *          "type": "document",
 *          "style": null,
 *          "profil_id": null,
 *          "societe_id": null
 *      },
 *      "category_group": null
 *  }
 *  ];
 * 
 *  ```
 * 
 * @param {Array|String} filters 
 *  
 *
 *  ```js
 *      
 *  let fitlers = ['name', 'profil.name'];
 *  let fitlers = 'name';
 * 
 *  ```
 * 
 * 
 * @param {String} search 
 * ``` js
 * let search = 'due'
 * ```
 * @returns {Array}
 */
export function filterBy(arr, filters, search){
    const ref = [...arr].map(function(v, i) {
        v['_keyindex'] = i;
        return v;
    });
    let founded = [];
    search = search.toLowerCase();
    if(typeof filters == "string"){
        filters = [filters];
    }
    if(search.length > 0){
        let filtered = [...ref];
        for(let filter of filters) {
            
            filtered = filtered.filter(function(a) {
                let b = a;

                /**
                 *  a = 
                 *      {
                 *          name: 'Jean-Luc',
                 *          profil: {
                 *              name: 'Jean-Charles',
                 *          },
                 *          _keyindex: 0
                 *      }
                 * 
                 *  filter = 'profil.name'
                 *  filter.split('.') = ['profil', 'name'];
                 *      b = a;
                 * 
                 *  -> for
                 *      b = b['profil'] = { name: 'Jean-Charles' };
                 *      b = b['name'] = 'Jean-Charles'; 
                 *  
                 * 
                 */
                for(let f of filter.split('.')){
                    if(b[f]){
                        b = b[f];
                    }
                }
                if(b instanceof Object){
                    return (JSON.stringify(b)).includes(search);
                }
                else if(Array.isArray(b)){
                    return (b).includes(search);
                } else {
                    return ((b).toString().toLowerCase()).includes(search);
                }
            });

            founded = [...founded, ...filtered];
            const indexes = founded.map(r => r['_keyindex']);
            filtered = [...(ref).filter(r => !indexes.includes(r['_keyindex']))];
        }
        return founded;
    } else {
        return ref;
    }
}