import { authActions } from '@/services/authentication-service';
import { decrypt, encrypt } from '@/services/cypher-service';
import { getLocal, removeLocal, saveLocal } from '@/services/local-storage-service';
import { defineStore } from 'pinia'
import { TeamStore } from './team';

export const UserStore = defineStore('user', {

    // Attributs
    state: () => ({
      email: null,
      profil: null,
      id_token: null,
      access_token: null,
      refresh_token: null,
      espace: "salarie",
    }),

    // Getters
    getters: {
      getEmail: (state) => state.email,
      getProfil: (state) => state.profil,
      getIdToken: (state) => decrypt(state.id_token),
      getAccessToken: (state) => decrypt(state.access_token),
      getRefreshToken: (state) => decrypt(state.refresh_token),
      getName: (state) => state.profil ? `${state.profil.fist_name} ${state.profil.last_name}` : null,
      getEspace: (state) => state.espace,
    },

    // Methods
    actions: {
      setTokens(tokens, saveLocally) {
        saveLocally = saveLocally || true;
        this.id_token = encrypt(tokens.id_token || tokens.token || tokens);
        this.access_token = encrypt(tokens.access_token || tokens.token || tokens);
        this.refresh_token = encrypt(tokens.refresh_token || tokens.token || tokens);
        if(saveLocally) this.saveTokensLocal();
      },
      setUserData(user){
        this.email = user.email || user.data.email;
        this.profil = user.profil || user.data.profil;
      },
      clearTokens(){
        this.id_token = null;
        this.access_token = null;
        this.refresh_token = null;
      },
      async getAuthUser() {
        try {
          const res = await authActions.getAuthUser();
          this.email = res.data.email;
          this.profil = res.data.profil;
          return res.data;
        } catch (e) {
          console.error(e);
          return false;
        }
      },
      saveTokensLocal() {
          saveLocal('_AT', this.access_token);
          saveLocal('_RT', this.refresh_token);
          saveLocal('_ID', this.id_token);
      },
      getTokensLocal() {
          this.access_token = getLocal('_AT');
          this.refresh_token = getLocal('_RT');
          this.id_token = getLocal('_ID');
      },
      clearTokensLocal(){
        removeLocal("_AT");
        removeLocal("_RT");
        removeLocal("_ID");
      },
      hasToken(name) {
          name = name || 'access';
          return this[`${name}_token`] != null;
      },
      hasRoles(roles){
        const team = TeamStore();
        return team.hasRoles(roles);
      },
      

    }

})