import { dd } from '@/helpers';
import { getLocal, removeLocal, saveLocal } from '@/services/local-storage-service';
import { resource } from '@/services/resources-service';
import { defineStore } from 'pinia'

const usefulls = ['DUE', 'Contrat', 'Contrat Assureur'];

export const DocumentStore = defineStore('document', {

    // Attributs
    state: () => ({
        all: [],
        mutuelles: [],
        prevoyances: [],
        persos: [],
        globals: [],
    }),

    // Getters
    getters: {
        $all: (state) => state.all,
        $mutuelles: (state) => state.mutuelles,
        $prevoyances: (state) => state.prevoyances,
        $persos: (state) => state.persos,
        $globals: (state) => state.globals,

        hasDocuments: (state) => state.all.length > 0,
        hasMutuelles: (state) => state.mutuelles.length > 0,
        hasPrevoyances: (state) => state.prevoyances.length > 0,
        hasPersos: (state) => state.persos.length > 0,
        hasGlobals: (state) => state.globals.length > 0,
    },

    // Methods
    actions: {
        async refreshDocuments(){
            try {
                const response = await resource('documents').index()
                this.setDocuments(response.data);
                this.saveDocumentsLocal();
                return response;
            } catch (err) {
                dd(err);
            }
        },
        setDocuments(data) {
            this.all = data;
            this.mutuelles = data.filter(d => d.contrat_type == "mutuelle") || [];
            this.prevoyances = data.filter(d => d.contrat_type == "prevoyance") || [];
            this.persos = data.filter(d => d.user_id != null ) || [];
            this.globals = data.filter(d => d.user_id == null ) || [];
        },

        saveDocumentsLocal(){
            saveLocal('documents', {
                all: this.all,
                mutuelles: this.mutuelles,
                prevoyances: this.prevoyances,
                persos: this.persos,
                globals: this.globals,
            });
        },

        getDocumentsLocal(){
            const documents = getLocal('documents');
            if(!documents){
                console.log('getdocumentsLocal FALSE', documents);
                return false;
            }
            this.all = documents.all || [];
            this.mutuelles = documents.mutuelles || [];
            this.prevoyances = documents.prevoyances || [];
            this.persos = documents.persos || [];
            this.globals = documents.globals || [];
            return documents;
        },

        clearDocumentsLocal(){
            removeLocal('documents');
        },

        has(contrat_type, perso, usefull){
            contrat_type = ['mutuelle', 'prevoyance'].includes(contrat_type) ? `${contrat_type}s` : (contrat_type || "all");
            if(this.hasDocuments){
                let docs = this[contrat_type];
                if(perso !== undefined){
                    docs = docs.filter(d => perso ? d.user_id !== null : d.user_id == null);
                }
                if(usefull !== undefined){
                    docs = docs.filter(d => usefull ? usefulls.includes(d.category.name) : !usefulls.includes(d.category.name));
                }
                return docs.length > 0;
            }
            return false;
        },

        usefulls(contrat_type, perso){
            contrat_type = ['mutuelle', 'prevoyance'].includes(contrat_type) ? `${contrat_type}s` : (contrat_type || "all");

            if(this.hasDocuments){
                let docs = this[contrat_type];
                docs = docs.filter(d => usefulls.includes(d.category.name));
                if(perso !== undefined){
                    docs = docs.filter(d => perso ? d.user_id !== null : d.user_id == null);
                }
                return docs;
            }
            
        },
        
        persosBy(contrat_type){
            contrat_type = ['mutuelle', 'prevoyance'].includes(contrat_type) ? `${contrat_type}s` : (contrat_type || "all");
            if(this.hasDocuments){
                let docs = this[contrat_type];
                docs = docs.filter(d => d.user_id !== null);
                return docs;
            }
        },
        globalsBy(contrat_type){
            contrat_type = ['mutuelle', 'prevoyance'].includes(contrat_type) ? `${contrat_type}s` : (contrat_type || "all");
            if(this.hasDocuments){
                let docs = this[contrat_type];
                docs = docs.filter(d => d.user_id == null);
                return docs;
            }
        }
    }

})