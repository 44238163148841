import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import './index.scss'
import { createPinia } from 'pinia'
import router from './routes'
import { helpersPlugin } from './helpers'
import { authDirectives } from './services/authentication-service'

const pinia = createPinia();

const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(helpersPlugin)

for(let [key, directive] of Object.entries(authDirectives)){
    app.directive(key, directive);
}

// app.directive('auth', authDirectives.auth);
// app.directive('hasRole', authDirectives.hasRole);

app.mount('#app');
