import { getLocal, removeLocal, saveLocal } from '@/services/local-storage-service';
import { defineStore } from 'pinia'

export const TeamStore = defineStore('team', {

    // Attributs
    state: () => ({
        id: null,
        uuid: null,
        infos: null,
        name: null,
        roles: null,
        teams: {},
    }),

    // Getters
    getters: {
        getSociete: (state) => state.infos,
        cateam: (state) => state.infos.cateam,
        cateam_salarie: (state) => state.infos.cateam_salarie,
    },

    // Methods
    actions: {
        selectTeam(teamUuid){
            const selectedTeam = this.teams[teamUuid];
            if(!selectedTeam) {
                return console.log('team not founded');
            }
            this.id = selectedTeam.id;
            this.uuid = teamUuid;
            this.infos = selectedTeam;
            this.name = selectedTeam.name;
            this.roles = selectedTeam.roles;
        },
        setTeams(teams, selected){
            selected = selected || 0;
            this.teams = teams;
            if(Number.isInteger(selected)){
                this.selectTeam(Object.entries(teams)[selected][0]);
            } else if(typeof selected === 'string' ) {
                this.selectTeam(selected);
            }
            this.saveTeamsLocal();
        },
        hasTeams() {
            return this.teams.length >= 0;
        },
        saveTeamsLocal(){
            saveLocal('teams', this.teams);
            saveLocal('uuid', this.uuid);
        },
        getTeamsLocal(){
            this.teams = getLocal('teams');
            if(this.teams == {}){
                return false;
            }

            this.uuid = getLocal('uuid');
            if(!this.uuid){
                return false;
            }
            this.selectTeam(this.uuid);
            return true;
        },
        clearTeamsLocal(){
            removeLocal('teams');
            removeLocal('uuid');
        },
        hasRoles(roles){
            // console.log(roles, this.roles);
            if(this.roles){
                if(Array.isArray(roles)){
                    return this.roles.some(r => roles.includes(r.name));
                } else if (typeof roles === 'string' ){
                    return this.roles.some(r => roles == r.name);
                }
            }
            return false;
        }
    }

})