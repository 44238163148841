import authenticate from "@/middlewares/auth";
import { toMutuelle, toPrevoyance, toAll, toCurrent } from "@/middlewares/contract";
import { hasDueWaiting } from "@/middlewares/due";
import { hasRoleSalarieOnTeam, hasRolesTeam } from "@/middlewares/team";

export const salarieRoutes = [
    {
        path: "/salarie/dashboard",
        name: "salarie.dashboard",
        component: () => import("@/views/salarie/Dashboard"),
        beforeEnter: [authenticate, hasRolesTeam, hasRoleSalarieOnTeam, hasDueWaiting],
      },
      {
        path: "/salarie/mutuelle/dashboard",
        name: "salarie.mutuelle.dashboard",
        component: () => import("@/views/salarie/DashboardCategory"),
        beforeEnter: [authenticate, hasRolesTeam, hasRoleSalarieOnTeam, toMutuelle],
        props: {category: 'mutuelle'}
      },
      {
        path: "/salarie/prevoyance/dashboard",
        name: "salarie.prevoyance.dashboard",
        component: () => import("@/views/salarie/DashboardCategory"),
        beforeEnter: [authenticate, hasRolesTeam, hasRoleSalarieOnTeam, toPrevoyance],
        props: {category: 'prevoyance'}
      },
      {
        path: "/salarie/mutuelle/due",
        name: "salarie.mutuelle.due",
        component: () => import("@/views/salarie/Due"),
        beforeEnter: [authenticate, hasRolesTeam, hasRoleSalarieOnTeam, toMutuelle],
        props: {category: 'mutuelle'}
      },
      {
        path: "/salarie/prevoyance/due",
        name: "salarie.prevoyance.due",
        component: () => import("@/views/salarie/Due"),
        beforeEnter: [authenticate, hasRolesTeam, hasRoleSalarieOnTeam, toPrevoyance],
        props: {category: 'prevoyance'}
      },
      {
        path: "/salarie/all/documents",
        name: "salarie.all.documents",
        component: () => import("@/views/salarie/Documents"),
        beforeEnter: [authenticate, hasRolesTeam, hasRoleSalarieOnTeam, toAll],
        props: {category: 'all'}
      },
      {
        path: "/salarie/mutuelle/documents",
        name: "salarie.mutuelle.documents",
        component: () => import("@/views/salarie/Documents"),
        beforeEnter: [authenticate, hasRolesTeam, hasRoleSalarieOnTeam, toMutuelle],
        props: {category: 'mutuelle'}
      },
      {
        path: "/salarie/prevoyance/documents",
        name: "salarie.prevoyance.documents",
        component: () => import("@/views/salarie/Documents"),
        beforeEnter: [authenticate, hasRolesTeam, hasRoleSalarieOnTeam, toPrevoyance],
        props: {category: 'prevoyance'}
      },
      {
        path: "/salarie/mutuelle/exchanges",
        name: "salarie.mutuelle.exchanges",
        component: () => import("@/views/salarie/Exchanges"),
        beforeEnter: [authenticate, hasRolesTeam, hasRoleSalarieOnTeam, toMutuelle],
        props: {category: 'mutuelle'}
      },
      {
        path: "/salarie/prevoyance/exchanges",
        name: "salarie.prevoyance.exchanges",
        component: () => import("@/views/salarie/Exchanges"),
        beforeEnter: [authenticate, hasRolesTeam, hasRoleSalarieOnTeam, toPrevoyance],
        props: {category: 'prevoyance'}
      },
      {
        path: "/salarie/all/exchanges",
        name: "salarie.all.exchanges",
        component: () => import("@/views/salarie/Exchanges"),
        beforeEnter: [authenticate, hasRolesTeam, hasRoleSalarieOnTeam, toAll],
        props: {category: 'all'}
      },
      {
        path: "/salarie/exchange/:exchangeId",
        name: "salarie.exchange",
        component: () => import("@/views/salarie/Exchange"),
        beforeEnter: [authenticate, hasRolesTeam, hasRoleSalarieOnTeam],
      },
      {
        path: "/salarie/exchange/track/:exchangeId",
        name: "salarie.exchange.track",
        component: () => import("@/views/salarie/ExchangeTrack"),
        beforeEnter: [authenticate, hasRolesTeam, hasRoleSalarieOnTeam],
      },
      {
        path: "/salarie/contact",
        name: "salarie.contact",
        component: () => import("@/views/salarie/Contact"),
        beforeEnter: [authenticate, hasRolesTeam, hasRoleSalarieOnTeam],
      },
      {
        path: "/salarie/:category/new-request",
        name: "salarie.new-request",
        component: () => import("@/views/salarie/NewRequest"),
        beforeEnter: [authenticate, hasRolesTeam, hasRoleSalarieOnTeam, toCurrent],
      },
      {
        path: "/salarie/:category/due/derogation",
        name: "salarie.due.derogation",
        component: () => import("@/views/salarie/DueDerogation"),
        beforeEnter: [authenticate, hasRolesTeam, hasRoleSalarieOnTeam, toCurrent],
      },
]