import { resource } from "@/services/resources-service";
import { UserStore } from "@/store/user";

const redirectName = "login";

export default async function authenticate(to) {
  const user = UserStore(); 

  if(!user.hasToken('id') && to.name !== redirectName) {
    return { name: redirectName};
  }

  const userinfos = await user.getAuthUser();
  
  if(!userinfos){
    return { name: redirectName };
  }

  return true;

}

export async function validRegisterToken(to){
  if(!to.params.token){
    return { name: redirectName};
  }
  const token = to.params.token;
  try {
    await resource('invitation').show(token);
    return true;
  } catch (error) {
    return { name: redirectName};
  }
}