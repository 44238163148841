import { TeamStore } from "@/store/team";
import { UserStore } from "@/store/user";
import { $api } from "./api-service"
import { $csrf } from "./authentication-service";

/**
 * ResourceMethods
 * 
 * class permet d'interragir avec les resources du back
 * 
 * Tableau :
 * 
 * | Function              | CRUD       | URL          |
 * | :---------------------| :----------| :------------|
 * | `index()`             | **GET**    | /resource    |
 * | `store(payload)`      | **POST**   | /resource    |
 * | `show(id)`            | **GET**    | /resource/id |
 * | `update(payload, id)` | **PUT**    | /resource/id |
 * | `destroy(id)`         | **DELETE** | /resource/id |
 * 
 * 
 * @attr {String} resource 
 */
export class ResourceMethods {

    #resource = "";
    #headers = {};
    #responseType = null;

    constructor(resource) {
        this.#resource = resource;

        const user = UserStore();
        const team = TeamStore();

        this.#headers = {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${user.getAccessToken}`,
            'Scope': team.uuid,
        }
        this.#responseType = null;
    }

    /**
     * index
     * 
     * Affiche la liste de données
     * 
     * @returns {Promise<AxiosResponse<any, any>>}
     */
    async index(){
        await $csrf();
        return $api.get(`/${this.#resource}`, {headers: this.#headers, responseType: this.#responseType});
    }

    /**
     * store
     * 
     * Enregistre un nouvel élément
     * 
     * @param {*} payload 
     * @returns {Promise<AxiosResponse<any, any>>}
     */
    async store(payload){
        await $csrf();
        if(payload instanceof FormData) {
            this.#headers['Content-Type'] = 'multipart/form-data';
        }
        if(this.#headers['Content-Type'] == 'multipart/form-data' && !(payload instanceof FormData)){
            payload = toFormData(payload);
        } 
        return $api.post(`/${this.#resource}`, payload, {headers: this.#headers, responseType: this.#responseType});
    }

    /**
     * show
     * 
     * Affiche une resource en particulier
     * 
     * @param {number} id
     * @returns {Promise<AxiosResponse<any, any>>}
     */
    async show(id){
        await $csrf();
        return $api.get(`/${this.#resource}/${id}`, {headers: this.#headers, responseType: this.#responseType});
    }

    /**
     * update
     * 
     * modifie une resource en particulier
     * 
     * @param {*} payload 
     * @param {number} id
     * @returns {Promise<AxiosResponse<any, any>>} 
     */
    async update(payload, id){
        await $csrf();
        if(this.#headers['Content-Type'] == 'multipart/form-data'){
            console.error("Si vous envoyez un fichier avec cette methode, il ne sera pas prit en compte");
        } 
        return $api.put(`/${this.#resource}/${id}`, payload, {headers: this.#headers, responseType: this.#responseType});
    }

    /**
     * destroy
     * 
     * Supprimer une resource en particulier
     * 
     * @param {number} id
     * @returns {Promise<AxiosResponse<any, any>>} 
     */
    async destroy(id){
        await $csrf();
        return $api.delete(`/${this.#resource}/${id}`, {headers: this.#headers, responseType: this.#responseType});
    }

    /**
     * file
     * 
     *      Change le header de la requête pour accepter les fichiers
     * 
     * @returns {ResourceMethods}
     */
    file(){
        this.#headers['Content-Type'] = 'multipart/form-data';
        return this;
    }

    /**
     * blob
     * 
     *      Change le header de la requête pour accepter les fichiers
     * 
     * @returns {ResourceMethods}
     */
     blob(){
        this.#responseType = 'blob';
        return this;
    }
}


/**
 * resource
 * 
 * Fonction qui retourne une instance de ResourceMethods
 * 
 * @param {String} resource 
 * @returns {ResourceMethods}
 */
export const resource = (resource) => new ResourceMethods(resource);

/**
 * toFormData
 * 
 *  Transforme un objet en formData
 * 
 * @param {Object} obj
 * @return {FormData}
 */
export function toFormData(obj) {
    let fd = new FormData();
    console.log(obj, Object.entries(obj));
    for (let val of Object.entries(obj)){
        fd.append(val[0], val[1]);
    }
    return fd;
}