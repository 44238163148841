import { ErrorStore } from "@/store/errors";
import { UserStore } from "@/store/user";
import axios from "axios"

export const $api = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/api",
  withCredentials: true,
});

/*
 * Add a response interceptor
 */
$api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const errorStore = ErrorStore();
    errorStore.$reset();
    errorStore.pushErrors(error.response.data.errors || error.response.data || {'message': error.message || "Une erreur s'est produite"});
    return Promise.reject(error);
  }
);


export const apiActions = {
  
  testGET(){
    const user = UserStore();
    return $api.get('/testing/GET', { headers: {'Authorization': `Bearer ${user.getAccessToken}` }});
  }, 
  testPOST(){
    return $api.post('/testing/POST');
  }, 
}