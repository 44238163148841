import { isJSON } from '@/helpers';
import { defineStore } from 'pinia'

export const ErrorStore = defineStore('error', {

    // Attributs
    state: () => ({
        errors: {}
    }),

    // Getters
    getters: {
        getErrors: (state) => state.errors,
    },

    // Methods
    actions: {
        has(key) {
            return this.errors[key] !== undefined;
        },
        message(key)
        {
            if(this.errors[key]) {
                if(Array.isArray(this.errors[key])){
                    return this.errors[key].join('. ');
                }
                return this.errors[key];
            }
            return this.default();
        },
        default()
        {
            return "Une erreur s'est produite"
        },
        pushErrors(err) {
            if(typeof err == "string"){
                this.errors["message"] = err;
            } else if(isJSON(err)){
                for(let [k,v] of Object.entries(JSON.parse(err))){
                    this.errors[k] = v;
                }
            } else {
                for(let [k,v] of Object.entries(err)){
                    this.errors[k] = v;
                }
            }
        }
    }

})