import { dd } from '@/helpers';
import { getLocal, removeLocal, saveLocal } from '@/services/local-storage-service';
import { resource } from '@/services/resources-service';
import { defineStore } from 'pinia'
import { SessionStateStore } from './session_states';

export const DUEStore = defineStore('dues', {

    // Attributs
    state: () => ({
        all: [],
        selected: null,
    }),

    // Getters
    getters: {
        $all: (state) => state.all,
        $selected: (state) => state.selected,
        hasDues: (state) => state.all.length > 0,
        mutuelle: (state) => state.all.find(d => d.type == 'mutuelle'),
        prevoyance: (state) => state.all.find(d => d.type == 'prevoyance'),
        current: (state) => state.all.find(d => d.type == (SessionStateStore()).current),
        waitings: (state) => state.all.filter(d => !d.pivot.derogated_at && !d.pivot.accepted_at && !d.pivot.refused_at),
        hasWaiting: (state) => state.all.some(d => !d.pivot.derogated_at && !d.pivot.accepted_at && !d.pivot.refused_at),
    },

    // Methods
    actions: {

        async refreshDUES(){
            try {
                const response = await resource('dues').index()
                this.setDUES(response.data);
                this.saveDUESLocal();
                return response;
            } catch (err) {
                dd(err);
            }
        },

        setDUES(data){
            this.all = data;
        },

                
        selecteByCategory(category){
            this.selected = this.all.find(d => d.type == category);
        },

        saveDUESLocal(){
            saveLocal('dues', {
                all: this.all,
                selected: this.selected,
            });
        },

        getDUESLocal(){
            const dues = getLocal('dues');
            if(!dues){
                return false;
            }
            this.all = dues.all || [];
            this.selected = dues.selected || null;
            return dues;
        },

        clearDUESLocal(){
            removeLocal('dues');
        },

        hasType(type) {
            return this.all.some(d => d.type == type);
        }
    }

})