import { getLocal, removeLocal, saveLocal } from '@/services/local-storage-service';
import { defineStore } from 'pinia'

export const ExchangeStore = defineStore('exchanges', {

    // Attributs
    state: () => ({
      todos: [],
      ondos: [],
      dones: []
    }),

    // Getters
    getters: {
      getTodos: (state) => state.todos,
      getOndos: (state) => state.ondos,
      getDones: (state) => state.dones,
      getAll: (state) => [...state.todos, ...state.ondos, ...state.dones],
    },

    // Methods
    actions: {
      setExchanges(data){
        this.todos = data.filter(ex => ["WAITING_CLIENT"].includes(ex.status));
        this.ondos = data.filter(ex => ["WAITING_AGENT", "WAITING", "OPENED", null].includes(ex.status));
        this.dones = data.filter(ex => ['REFUSED', 'CLOSED', 'ACCEPTED'].includes(ex.status));
        this.saveExchangesLocal();
      },
      saveExchangesLocal(){
        saveLocal('exchanges', {todos: this.todos, ondos: this.ondos, dones:this.dones,});
      },
      getExchangesLocal(){
        const exchanges = getLocal('exchanges');
        if(!exchanges){
          console.log('getExchangesLocal FALSE', exchanges);
          return false;
        }
        this.todos = exchanges.todos || [];
        this.ondos = exchanges.ondos || [];
        this.dones = exchanges.dones || [];
        return exchanges;
      },
      clearExchangesLocal(){
        removeLocal('exchanges');
      },
      findBy(attr, val){
        console.log(this.ondos);
        return this.getAll.find(ex => ex[attr] == val);
      },
      getState(exchange){
        if(this.todos.some(ex => ex.id == exchange.id)){
          return 'todo';
        }
        if(this.ondos.some(ex => ex.id == exchange.id)){
          return 'ondo';
        }
        if(this.dones.some(ex => ex.id == exchange.id)){
          return 'done';
        }
        return 'ondo';
      }

    }

})