import { TeamStore } from "@/store/team";
import { UserStore } from "@/store/user";

const redirectName = "login";

// export default function auth({ to, next, store }) {
//     const loginQuery = { path: "/login", query: { redirect: to.fullPath } };
  
//     if (!currentUser.getIdToken()) {
      
//       store.dispatch("auth/getAuthUser").then(() => {
//         if (!store.getters["auth/authUser"]) next(loginQuery);
//         else next();
//       });
//     } else {
//       next();
//     }
// }

export function hasTeams(to) {
  const team = TeamStore(); 

  if(!team.hasTeams() && to.name !== redirectName) {
    return { name: redirectName};
  }

  return true;

}

export function hasRolesTeam(to) {
    const user = UserStore();
    // console.log(user.hasRoles(['salarie', 'entreprise', 'agent']));
    if(!user.hasRoles(['salarie', 'entreprise', 'agent']) && to.name !== redirectName){
        return { name: redirectName }
    }
    return true;
}

export function hasRoleSalarieOnTeam(to) {
    const user = UserStore();
    // console.log(user.hasRoles(['salarie', 'entreprise']));
    if(!user.hasRoles(['salarie', 'entreprise']) && to.name !== redirectName){
        return { name: redirectName }
    }
    return true;
}

export function hasRoleEntrepriseOnTeam(to) {
    const user = UserStore();
    // console.log(user.hasRoles('entreprise'));
    if(!user.hasRoles('entreprise') && to.name !== redirectName){
        return { name: redirectName }
    }
    return true;
}