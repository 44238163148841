<template>
  <div
    v-show="showBanner"
    class="fixed bottom-0 inset-x-0 mx-auto w-full max-w-[700px] flex justify-between bg-white shadow py-3 px-5"
  >
    <div>
      <p class="font-title text-blue font-bold mb-2">
        <img
          :src="asset('/logo.png')"
          alt="Logo Vasa pour l'application Mobile"
          class="max-h-14 p-2 pl-0 inline"
        />
        Télécharger l'application Vasa
      </p>
      <ol v-if="showIosInstructions" class="list-decimal mb-2 ml-4">
        <li>
            Appuyer sur 
            <img :src="asset('/pictos/share_apple.svg')" alt="Button paratger" class="inline max-h-8 mb-2 ml-2">
        </li>
        <li>Séléctionnez ‟<strong class="text-blue">Sur l'écran d'accueil</strong>”</li>
      </ol>
      <button
        v-else-if="isIos"
        type="button"
        v-on:click="showIosInstructions = true"
        class="bg-pink rounded-md text-white font-bold py-2 px-10"
      >
        Installer
      </button>
      <button
        v-else
        type="button"
        v-on:click="prompt"
        class="bg-pink rounded-md text-white font-bold py-2 px-10"
      >
        Installer
      </button>
    </div>
    <img
      :src="asset('/pictos/refuser_suivi_3.svg')"
      alt="refuser la proposition"
      class="max-h-6 cursor-pointer"
      v-on:click="disableBanner"
    />
  </div>
</template>

<script>
import {
  getLocal,
  saveLocal,
  removeLocal,
} from "@/services/local-storage-service";

export default {
  name: "InstallBanner",
  props: {
    dismissBannerDateKey: {
      type: String,
      default: "InstallBannerDismissDate",
    },
    // Temps, en nombre de jour(s), pendant lequel la bannière est désactivée si on la ferme
    dismissDuration: {
      type: Number,
      default: 90,
    },
  },
  data: function () {
    return {
      showBanner: false,
      beforeInstallPromptEvent: null,
      dismissBannerDate: getLocal(this.dismissBannerDateKey),
      showIosInstructions: false,
    };
  },
  beforeMount: function () {
    // Pour Chrome uniquement
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.beforeInstallPromptEvent = e;
      // Update UI notify the user they can install the PWA
      this.showInstallBanner();
      // Optionally, send analytics event that PWA install promo was shown.
    });
    // Pour iOs uniquement
    if (this.isIos && !this.isInStandaloneMode) {
      this.showInstallBanner();
    }

    window.addEventListener("appinstalled", () => {
      // Hide the app-provided install promotion
      this.hideInstallBanner();
      // Clear the beforeInstallPromptEvent so it can be garbage collected
      this.beforeInstallPromptEvent = null;
      // Optionally, send analytics event to indicate successful install
    });
  },
  methods: {
    prompt: async function () {
      this.hideInstallBanner();

      // Show the install prompt
      this.beforeInstallPromptEvent.prompt();

      // // Wait for the user to respond to the prompt
      // const { outcome } = await this.beforeInstallPromptEvent.userChoice;
      // // Optionally, send analytics event with outcome of user choice
      // console.log(`User response to the install prompt: ${outcome}`);

      // We've used the prompt, and can't use it again, throw it away
      this.beforeInstallPromptEvent = null;
      removeLocal(this.dismissBannerDateKey);
    },
    showInstallBanner: function () {
      if (!this.isBannerDisabled) {
        this.showBanner = true;
      }
    },
    hideInstallBanner: function () {
      this.showBanner = false;
    },
    disableBanner: function () {
      const now = String(Date.now());
      saveLocal(this.dismissBannerDateKey, now);
      this.dismissBannerDate = now;
      this.hideInstallBanner();
    },
  },
  computed: {
    isBannerDisabled: function () {
      if (this.dismissBannerDate) {
        const daysSinceDismiss = (Date.now() - this.dismissBannerDate) / 1000;
        if (daysSinceDismiss < this.dismissDuration) {
          return true;
        }
      }
      return false;
    },
    isIos: function () {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    },
    isInStandaloneMode: function () {
      return "standalone" in window.navigator && window.navigator.standalone;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>