import { defineStore } from 'pinia';
import { dd } from '@/helpers';
import { resource } from '@/services/resources-service';
import { getLocal, removeLocal, saveLocal } from '@/services/local-storage-service';

export const ContratStore = defineStore('contrat', {

    // Attributs
    state: () => ({
        all: [],
    }),
    getters: {
        $all: (state) => state.all,
        mutuelle: (state) => state.all.find(c => c.type == 'mutuelle'),
        prevoyance: (state) => state.all.find(c => c.type == 'prevoyance'),
        hasMutuelle: (state) => state.all.some(c => c.type == 'mutuelle'),
        hasPrevoyance: (state) => state.all.some(c => c.type == 'prevoyance'),
        has: (state) => state.all.length > 0,
    },
    // Methods
    actions: {
        async refreshContrats(){
            try {
                const response = await resource('contrats').index()
                this.setContrats(response.data);
                this.saveContratsLocal();
                return response;
            } catch (err) {
                dd(err);
            }
        },
        setContrats(data){
            this.all = data;
        },
        saveContratsLocal(){
            saveLocal('contrats', {
                all: this.all,
            });
        },
        getContratsLocal(){
            const contrats = getLocal('contrats');
            if(!contrats) {
                return false;
            }
            this.setContrats(contrats.all);
            return contrats;
        },
        clearContratsLocal(){
            removeLocal('contrats');
        },
        byType(type){
            return this.all.find(c => c.type == type);
        },
        hasType(type){
            return this.all.some(c => c.type == type);
        }
    }

})