import { DocumentStore } from "@/store/documents";
import { DUEStore } from "@/store/dues"

export async function hasDueWaiting() {
    const dues = DUEStore();
    try {
        await dues.refreshDUES();
        if(dues.hasWaiting){
            const due = dues.waitings[0];
            const docs = DocumentStore();
            await docs.refreshDocuments();
            return { path: `/salarie/${due.type}/due`};
        } else {
            return true;
        }
    } catch (err) {
        console.log(err);
        return true;
    }
}