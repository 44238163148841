import { SessionStateStore } from "@/store/session_states";

export function toMutuelle() {
    const session = SessionStateStore();
    session.toContrat('mutuelle');
    return true;
}

export function toPrevoyance() {
    const session = SessionStateStore();
    session.toContrat('prevoyance');
    return true;
}

export function toAll() {
    const session = SessionStateStore();
    session.toContrat('*');
    return true;
}

export function toCurrent(to) {
    const session = SessionStateStore();
    session.toContrat(to.params.category == "all" ? "*" : to.params.category);
    return true;
}