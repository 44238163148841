import { getLocal, removeLocal, saveLocal } from '@/services/local-storage-service';
import { resource } from '@/services/resources-service';
import { defineStore } from 'pinia'

export const PrevenStore = defineStore('preven', {

    // Attributs
    state: () => ({
      preventions: [],
      contents: [],
      cg: "https://preven.care/cgu/CGU-Preven.pdf",
      dataPrivacy: "https://preven.care/cgu/Data-Privacy-Preven.pdf"
    }),

    // Getters
    getters: {
      $preventions: (state) => state.preventions,
      $contents: (state) => state.contents,
    },

    // Methods
    actions: {
      async register(payload){
        try {
          return (await resource('preven/account').store(payload)).data.registered;
        } catch (err) {
          console.log(err);
          this.clearPrevenLocal();
          return false;
        }
      },
      async answer(payload){
        try {
          return (await resource('preven/preventions').store(payload)).data.registered
        } catch (err) {
          console.log(err);
          this.clearPrevenLocal();
          return false;
        }
      },
      async hasPreven(){
        try {
          const data = (await resource('preven/account').index()).data;
          const registered = data.registered;
          return registered;
        } catch (err) {
          console.log(err);
          this.clearPrevenLocal();
          return false;
        }
      },
      async setPreven(){
        try{
          this.preventions = (await resource('preven/preventions').index()).data;
          this.contents = (await resource('preven/contents').index()).data;
          this.savePrevenLocal();
          return true;
        } catch(err){
          console.log(err);
          this.clearPrevenLocal();
          return false;
        }
      },
      savePrevenLocal(){
        saveLocal('preven', {preventions: this.preventions, contents: this.contents});
      },
      getPrevenLocal(){
        const preven = getLocal('preven');
        this.contents = preven.contents;
        this.preventions = preven.preventions;
      },
      clearPrevenLocal(){
        removeLocal('preven');
      },
    }

})