import authenticate from "@/middlewares/auth";
import { hasRoleSalarieOnTeam, hasRolesTeam } from "@/middlewares/team";

export const prevenRoutes = [
    {
        path: "/preven/dashboard",
        name: "preven.dashboard",
        component: () => import("@/components/preven/RegisterButton"),
        beforeEnter: [authenticate, hasRolesTeam, hasRoleSalarieOnTeam],
    },
    {
        path: "/preven/register",
        name: "preven.register",
        component: () => import("@/components/preven/PrevenRegisterForm"),
        beforeEnter: [authenticate, hasRolesTeam, hasRoleSalarieOnTeam],
    },
]