import { isJSON } from "@/helpers";
import { SHA256 } from "crypto-js";
import { decrypt, encrypt } from "./cypher-service";


// Constantes
export const STORE_LOCAL = "local";

export const STORE_SESSION = "session";

export const STORE_COOKIE = "cookie";

class SavingMethod {
    constructor(method){
        this.method = method || STORE_LOCAL;
    }

    set(key, value) {
        switch (this.method) {
            case STORE_LOCAL:
                return localStorage.setItem(key, value);
            case STORE_SESSION:
                return sessionStorage.setItem(key, value);
            // case STORE_COOKIE:
            //     return cookieStore.set(key, value).then((r) => { return r;}).catch((e) => { return e;});
            default:
                console.log('methode de storage inconnue ou non prise en compte');
                return undefined;
        }
    }
    get(key) {
        switch (this.method) {
            case STORE_LOCAL:
                return localStorage.getItem(key);
            case STORE_SESSION:
                return sessionStorage.getItem(key);
            // case STORE_COOKIE:
            //     return cookieStore.get(key).then((r) => { return r; }).catch((e) => { return e; });
            default:
                console.log('methode de storage inconnue ou non prise en compte');
                return undefined;
        }
    }
    delete(key) {
        switch (this.method) {
            case STORE_LOCAL:
                return localStorage.removeItem(key);
            case STORE_SESSION:
                return sessionStorage.removeItem(key);
            // case STORE_COOKIE:
            //     return cookieStore.delete(key).then((r) => { return r; }).catch((e) => { return e; });
            default:
                console.log('methode de storage inconnue ou non prise en compte');
                return undefined;
        }
    }
    clear() {
        switch (this.method) {
            case STORE_LOCAL:
                return localStorage.clear();
            case STORE_SESSION:
                return sessionStorage.clear();
            // case STORE_COOKIE:
            //     return cookieStore.delete(key).then((r) => { return r; }).catch((e) => { return e; });
            default:
                console.log('methode de storage inconnue ou non prise en compte');
                return undefined;
        }
    }
}

function validationMethod(method){
    if(![STORE_LOCAL, STORE_SESSION, STORE_COOKIE].includes(method)){
        console.error('Storage Method not valid');
        return false;
    } 
    return true;
}

/**
 * 
 * Save Items Locally
 * 
 * @param String key
 * @param any value
 * @param String method = "local"
 * 
 * @return void 
 */
export function saveLocal(key, value, method){
    method = method || STORE_LOCAL;
    if(!validationMethod(method)){
        return undefined;
    }
    try {
        const savingMethod = new SavingMethod(method);
        if(Array.isArray(value) || ( typeof value === "object")){
            value = JSON.stringify(value);
        }
        savingMethod.set(SHA256(key), encrypt(value));
    } catch (e) {
        console.error(e);
        return undefined;
    }
}

/**
 * 
 * Get Items Saved Locally
 * 
 * @param String key
 * @param String method = "local"
 * 
 * @return any 
 */
export function getLocal(key, method){
    method = method || STORE_LOCAL;
    if(!validationMethod(method)){
        return undefined;
    }
    const savingMethod = new SavingMethod(method);

    try {
        const value = decrypt(savingMethod.get(SHA256(key)));
        if(isJSON(value)){
            return JSON.parse(value);
        } else {
            return value;
        }
    } catch (e) {
        console.error(e);
        return undefined;
    }
}

/**
 * 
 * Remove Item Locally
 * 
 * @param String key
 * @param String method = "local"
 * 
 * @return void 
 */
export function removeLocal(key, method){
    method = method || STORE_LOCAL;
    if(!validationMethod(method)){
        return undefined;
    }
    try {
        const savingMethod = new SavingMethod(method);
        return savingMethod.delete(SHA256(key));
    } catch (e) {
        console.error(e);
        return undefined;
    }
}

/**
 * 
 * Remove Item Locally
 * 
 * @param String key
 * @param String method = "local"
 * 
 * @return void 
 */
export function purgeLocal(method){
    method = method || STORE_LOCAL;
    if(!validationMethod(method)){
        return undefined;
    }
    try {
        const savingMethod = new SavingMethod(method);
        return savingMethod.clear();
    } catch (e) {
        console.error(e);
        return undefined;
    }
}