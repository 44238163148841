import authenticate from "@/middlewares/auth";
import { createWebHistory, createRouter } from "vue-router";
import { authRoutes } from "./auth";
import { salarieRoutes } from "./salarie";
import { prevenRoutes } from "./preven";
import { SessionStateStore } from "@/store/session_states";

const routes = [
    ...authRoutes,
    ...salarieRoutes,
    ...prevenRoutes,
    {
      path: "/",
      name: "home",
      component: () => import("@/components/HelloWorld"),
      beforeEnter: [authenticate, () => {return { name: 'salarie.dashboard' }}],
    },
    {
      path: "/notifications",
      name: "notifications",
      component: () => import("@/views/Notifications"),
      beforeEnter: [authenticate],
    },
    {
      path: "/profil",
      name: "profil",
      component: () => import("@/views/Profil"),
      beforeEnter: [authenticate],
    },
   
]


const router = createRouter({
  history: createWebHistory(),  
  routes,
  scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
  },
  
})

router.beforeEach(() => {
  (SessionStateStore()).startLoading();
});

router.afterEach(() => {
  (SessionStateStore()).endLoading();
})

export default router;