import { defineStore } from 'pinia'

export const SessionStateStore = defineStore('session_state', {

    // Attributs
    state: () => ({
        loading: false,
        contrat: "*",
    }),
    getters: {
        isLoading: (state) => state.loading,
        isMutuelle: (state) => state.contrat == "mutuelle",
        isPrevoyance: (state) => state.contrat == "prevoyance",
        isAll: (state) => ["*","all"].includes(state.contrat),
        current:(state) => state.contrat == "*" ? "all" : state.contrat, 
        currentFormatted:(state) => ["*","all" ].includes(state.contrat) ? "tout" : (state.contrat == "prevoyance" ? "prévoyance" : state.contrat), 
    },
    // Methods
    actions: {
        startLoading(){
            this.loading = true;
        },
        endLoading(){
            this.loading = false;
        },
        toContrat(category){
            this.contrat = category;
        },
    }

})