import { AES, enc } from "crypto-js";

export const encrypt = function(value) {
    if(!value) return null;
    return AES.encrypt(value, process.env.VUE_APP_KEY || "secret").toString();
}

export const decrypt = function(value) {
    if(!value) return null;
    return AES.decrypt(value, process.env.VUE_APP_KEY || "secret").toString(enc.Utf8);
}


